<template>
      <highcharts 
      :constructorType="'stockChart'"  
      :options="options" 
      ref="chart" 
      :callback="(event) => {
        this.$emit('elementLoaded', event)
      }"></highcharts>
</template>

<script>


export default {
  components: {
  },

  directives: {
  },

  created() {
  },

  methods: {

      updateElement()
      {

      },

 
  },

  mounted() {

    

  },

  beforeDestroy() {
    //console.log("beforeDestroy 2");
  },

  data() {
    return {
    };
  },

  props: {
    load: null,
    options: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~highcharts/css/stocktools/gui.css";
@import "~highcharts/css/annotations/popup.css";
</style>
